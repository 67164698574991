.csf__plywood_solution {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.csf__plywood_solution_heading {
    flex: auto;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
    margin-left: 1rem;
}

.csf__plywood_solution_heading h1 {
    font-size: 30px;
    line-height: 35px;
    font-family: var(--font-family);
    font-weight: 600;
}

.csf_plywood_solutions-content {
    display: flex;
    flex-direction: row;
}

.csf_plywood_solutions-content_image {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.csf_plywood_solutions-content_image img{
    max-height: 350px;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: center;
    transition: 200ms transform ease-in-out;
}

.csf_plywood_solutions-content_description {
    flex: 0.5;
    margin-top: 0.1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    width: 100%;
}

.csf_plywood_solutions-content_description div{
    padding: 0 0 1rem 0;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: justify;
    text-justify: inter-word;
    color: var(--color-text);
}

