@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,600&display=swap');

html, body, #root, .app{
    height: 100%;
    width: 100%;
    --font-family: 'Montserrat', sans-serif;
    color: #1C2E40;
    --color-text: #335475;
    --color-gray-bg: #fafafa;
    --color-bg: #ffffff;
}
