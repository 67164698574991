.formComponent{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formComponent input{
    font-family: var(--font-family);
    padding: 10px 10px 10px 15px;
    margin: 0.2rem 0px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 14px;
}

.formComponent textarea{
    font-family: var(--font-family);
    padding: 10px 10px 10px 15px;
    margin: 0.2rem 0px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 14px;
}


.formComponent select{
    -webkit-appearance: none;
    appearance: none;
    font-family: var(--font-family);
    padding: 10px 0px 10px 10px;
    margin: 0.5rem 0px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 14px;

    background-image: url("../../assets/down_arrow.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
}


.formComponent label{
    margin-top: 0.7rem;
    font-family: var(--font-family);
    font-size: 16px;
    color: #031B34;
}

.formComponent span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
    font-family: var(--font-family);
}

.formComponent input:invalid[lostFocus="true"]{
    border: 1px solid red;
}

.formComponent input:invalid[lostFocus="true"] ~ .formComponent span{
    display: block;
}