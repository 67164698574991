.csf__marine_plywood {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.csf__marine_plywood-heading {
    flex: auto;
    width: 100%;
    text-align: left;
}

.csf__marine_plywood-heading h1 {
    font-size: 35px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 600;
}
