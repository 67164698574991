.csf__contact{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 3rem;
}

.csf__contact-form {
    flex: auto;
    display: flex;
    flex-direction: column;
    width: 20%;
}

.csf__contact-heading {
    flex: auto;
    width: 100%;
    text-align: left;
    margin-bottom: 0.5rem;
}

.csf__contact-heading h1 {
    font-size: 27px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 600;
}


.csf__contact-form form{
    background-color: white;
    padding: 0px 0px;
    border-radius: 10px;
}

.csf__contact-form h1{
    color: rgb(77, 1, 77);
    text-align: center;
}

.csf__contact-form button{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: rebeccapurple;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
}


.csf__details {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-left: 4rem;
    width: 50%;
}

.csf__map {
    flex: auto;
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
}

.csf__map iframe{
    width: 100%;
    height: 430px;
}

.csf__address {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    width: 100%;
}

/* Large desktop */
@media screen and (min-width: 1280px) {}

/* landscape tablet to desktop */
@media (min-width: 916px) and (max-width: 1279px) {}

/* Landscape phone to portrait tablet */
@media (min-width: 481px) and (max-width: 915px) {
    .csf__contact{
        flex-direction: column;
    }

    .csf__contact-form {
        width: 100%;
    }

    .csf__contact-heading h1 {
        font-size: 25px;
        line-height: 50px;
        font-family: var(--font-family);
        font-weight: 600;
    }

    .csf__details {
        margin-top: 0;
        margin-left: 0;
        width: 100%;
    }

    .csf__map {
        margin-top: 1rem;
    }

    .csf__map iframe{
        width: 100%;
        height: 350px;
    }
}

/* Portrait phones */
@media (min-width: 220px) and (max-width: 480px) {
    .csf__contact{
        flex-direction: column;
    }

    .csf__contact-form {
        width: 100%;
    }

    .csf__contact-heading h1 {
        font-size: 25px;
        line-height: 50px;
        font-family: var(--font-family);
        font-weight: 600;
    }

    .csf__details {
        margin-top: 0;
        margin-left: 0;
        width: 100%;
    }

    .csf__map {
        margin-top: 1rem;
    }

    .csf__map iframe{
        width: 100%;
        height: 330px;
    }
}


